.wallet-notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 500;
    padding: 12px 15px;
}

.wallet-notification-error {
    background: #981f2b;
    color: #fff;
}

.wallet-notification-info {
    background: rgba(78, 68, 206, 0.7);
    color: #fff;
}

.wallet-notification-success {
    background: rgba(33, 229, 111, 0.7);
    color: #fff;
}

.faq-card {
    background-color: #fff;
    border-radius: 20px;
    max-width: 310px;
    margin-top: 90px;
    box-shadow: 0px 60px 50px -25px rgba(0, 0, 0, 0.35);
}


/* FAQ card: imagenes */


/*================================================*/

.faq-ilustrations {
    position: relative;
}

.faq-ilustrations .mobile.ilust {
    position: absolute;
    top: -110px;
    left: -30px;
    right: 0;
}

.mobile {
    display: block;
    margin: 0 auto;
}


/* FAQ card: main */


/*================================================*/

.faq-content {
    padding: 9px 25px 3rem;
}

.faq-content h1 {
    font-size: 32px;
    text-align: center;
    color: #fff;
}

.faq-accordion {
    padding: 8px 0;
    border-bottom: 1px solid hsl(240, 5%, 91%);
}


/* FAQ card: main title */


/*================================================*/


/* checkbox tgg-title*/

input.tgg-title {
    appearance: unset;
    all: unset;
}

.faq-accordion-title label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.faq-accordion-title h2 {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    padding: unset;
}

.faq-accordion-title span {
    margin-left: auto;
    transition: transform .4s ease-in-out;
}


/* FAQ card: main content */


/*================================================*/

.faq-accordion-content {
    color: #dbdbc5;
    overflow: hidden;
    max-height: 0;
    font-size: 12px;
    transition: max-height .4s ease-in-out;
}


/* Effects */


/*================================================*/


/* main title, accordion title effects */

.faq-accordion-title:hover h2 {
    color: #981f2b;
}


/* onclick "" */

.faq-accordion .tgg-title:checked+div>label>h2 {
    font-weight: 700;
}

.faq-accordion .tgg-title:checked+div>label>span {
    will-change: transform;
    transform: rotate(180deg);
}


/* main content, acordion text effect */

.faq-accordion .tgg-title:checked~.faq-accordion-content {
    will-change: max-height;
    max-height: 200px;
}

.arrow-icon img {
    width: 12px;
}