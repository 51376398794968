@font-face {
    font-family: 'AT_surt';
    src: url('./assest/AT_Surt_Regular.otf') format('woff2');
}

html,
body {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    background: transparent linear-gradient( 243deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
}

body {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    height: 100vh;
    /* width: 100vw; */
    background-size: cover;
    font-family: 'AT_surt';
    font-size: 1.1em;
    background: transparent linear-gradient( 243deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    background: url(./assest/Swirl_Long_Artwork_-_Red.png) no-repeat;
    background-size: cover;
    background-position: bottom right;
}

.navbar-dark .navbar-nav .nav-link {
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    background: #2c2d30;
}

nav>div {
    display: flex;
}

nav>div>button {
    margin-left: 1rem;
}

@media (max-width: 400px) {
    nav>h1 {
        max-width: 90px;
    }
}

h2 {
    padding: 0 15vw;
}

.trans {
    margin-top: 10px;
    display: block;
    background: #0b0f0f5c;
    border: black;
    border-radius: 10px;
    height: 30px;
    text-align: center;
    color: #f27720;
}

.trans-success {
    margin-top: 10px;
    display: block;
    background: #0b0f0f5c;
    border: black;
    border-radius: 10px;
    text-align: center;
    color: #8bc34a;
    font-weight: 600;
    padding: 15px;
}

.connect-wallet {
    background-color: #4e44ce;
}

.wallet-adapter-button,
.wallet-adapter-modal-wrapper {
    font: inherit;
}


/*  {
display: grid;
grid-template-columns: repeat(auto-fill, minmax(14vw, 1fr));
gap: 2rem;

position: relative;
/* max-width: 70vw;
padding: 0 15vw; 
} */

.card {
    display: inline-grid;
    place-content: center;
    gap: 0.25rem;
    padding: 5px;
    color: antiquewhite;
    background: #464d4f 0% 0% no-repeat padding-box;
    /* border: 1px solid #FFFFFF; */
    border-radius: 20px;
    opacity: 1;
    text-align: center;
}

.card img {
    max-width: 11rem;
    border-radius: 17px;
}

.card button {
    display: inline-block;
    margin: 0.1rem;
    font-size: 12px;
    font-family: inherit;
    color: inherit;
    background-color: #fff2;
    backdrop-filter: blur(8px);
    border: 0.25px solid #fff4;
    border-radius: 0.4rem;
    outline: none;
    cursor: pointer;
}

.counter {
    font-variant-numeric: tabular-nums;
}


/* Css For Navigation Page */

@media only screen and (min-width: 981px) {
    .logo img {
        width: 150px;
    }
}

@media only screen and (max-width: 981px) {
    body {
        background: unset;
        overflow: auto;
    }
    .tab-content {
        max-height: 300px !important;
        min-height: 300px !important;
    }
    .logo img {
        width: 130px;
    }
    .headline {
        font-size: 1.5em !important;
    }
    .sub-heading {
        width: 100% !important;
    }
    .header-menu-container {
        padding: 7px 0px 5px 0px !important;
    }
    nav {
        padding: 0 1rem;
    }
    .nav-social {
        display: inline-flex;
    }
    .nav-social-container {
        display: block;
        text-align: center;
    }
    .sub-container {
        padding: 0px 0px !important;
    }
    .countStyle {
        width: 100% !important;
    }
    .text-left {
        text-align: center !important;
    }
    .count-col-text {
        font-size: 12px !important;
    }
    .info-sub-heading,
    .sub-heading-1 {
        font-size: 18px !important;
    }
    .color-white {
        font-size: 14px !important;
    }
    .c-heading {
        font-size: 23px !important;
    }
}

.headline {
    font-size: 40px;
    color: #981f2b;
    letter-spacing: 0.8px;
    font-weight: 800;
}

.sub-heading {
    font-size: 16px;
    color: #fff;
    letter-spacing: 0.32px;
    padding-top: 15px;
    width: 66%;
    margin: auto;
    line-height: 30px;
}

.brand {
    width: 55%;
    /* border-radius: 25px;
    box-shadow: 0px 10px 45px #1e439580; */
}

.withdraw {
    display: inline-block;
    margin: 0.1rem;
    font-size: 1rem;
    font-family: inherit;
    color: inherit;
    background-color: #fff2;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border: 0.25px solid #fff4;
    border-radius: 0.4rem;
    outline: none;
    cursor: pointer;
}

.nft-nameStaked,
.nft-nameUnstaked,
.nft-nameStaked a,
.nft-nameUnstaked a {
    /* color: black; */
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.36px;
    margin-top: 12px;
    opacity: 1;
    text-align: center;
    max-width: unset;
    margin: unset;
    padding-top: 5px;
    padding-bottom: 5px;
    text-decoration: none;
    /* font-weight: 600; */
}

.stake {
    background-color: unset !important;
    background: transparent linear-gradient( 270deg, #4472c4 0%, #4472c4 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 10px !important;
    color: #fff !important;
    white-space: nowrap !important;
    text-align: center !important;
    border: unset;
    /* width: 100%; */
    /* box-shadow: 0 0 10px #212529; */
    text-transform: capitalize !important;
    min-width: unset;
    width: 100%;
    font-size: 9px !important;
    line-height: 35px !important;
}

.admin-un-stake {
    background-color: unset !important;
    background: transparent linear-gradient( 270deg, #981f2b 0%, #981f2b 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 10px !important;
    color: #fff !important;
    white-space: nowrap !important;
    text-align: center !important;
    border: unset;
    /* width: 100%; */
    /* box-shadow: 0 0 10px #212529; */
    text-transform: capitalize !important;
    min-width: unset;
    width: 100%;
    font-size: 15px !important;
    line-height: 35px !important;
}

.nft-img {}

.card-body {
    padding: unset;
}

.c-heading {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    opacity: 1;
    letter-spacing: 0.6px;
    padding-left: 25px;
    border-left: 5px #ffffff solid;
}

.column {
    float: left;
    width: 33%;
    font-size: 20px;
    font-family: 'AT_surt';
    font-weight: 600;
    color: #fff;
}

.column-admin {
    float: left;
    width: 100%;
    font-size: 20px;
    font-family: 'AT_surt';
    font-weight: 600;
    color: #fff;
}


/* Clear floats after the columns */

.row:after {
    content: "";
    display: table;
    clear: both;
}

.info-sub-heading {
    font-size: 20px;
}

.color-white {
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.32px;
}

.countStyle {
    /* border: 3px solid;
border-radius: 20px; */
    /* height: 50px; */
    font-size: xx-large;
    padding: 10px;
    border-radius: 10px;
    /* width: 80%; */
    margin: auto;
    /* background: #070b0d; */
}


/* 
.countStyle img {
    background: #981f2b;
    padding: 14px;
    border-radius: 10px;
} */

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(120deg, #0596cc, #1e4395);
    z-index: 10;
}

.headerFont {
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 38px;
    margin: 0 8.5px;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.headerFont:hover {
    color: #fff !important;
}

.btn {
    font-family: 'AT_surt';
    font-size: 12px;
    line-height: 46px;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
    color: #1e4395;
    font-weight: 800 !important;
    min-width: 140px;
    max-width: 100%;
    border: none;
    box-sizing: border-box;
    transition: all 0.5s;
    position: relative;
    z-index: 2;
    border-radius: 40px;
    background: white;
}

.sub-container {
    padding: 0px 46px;
    /* background: #1e4395; */
    max-width: 1100px;
    margin: auto;
}

.rewards {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 0px;
    letter-spacing: 0.32px;
    color: #fff;
    font-weight: 600;
}

.sub-heading-1 {
    font-size: 20px;
    letter-spacing: 0.4px;
    color: #fff;
}

.home-sellect-wallet-btn {
    background: unset;
    border: 2px solid #dbdbc5;
    border-radius: 10px;
    color: #dbdbc5;
    text-transform: capitalize;
    font-size: 20px;
    padding: 10px 35px;
}

.home-sellect-wallet-btn:hover,
.disconnect-btn:hover {
    color: #fff;
}

.text-left {
    text-align: left;
}

.count-col-right {
    padding-left: 20px;
    background: #070b0d;
    border-radius: 10px;
}

.count-col-text {
    font-size: 12px;
    letter-spacing: 0.36px;
    color: #dbdbc5;
    font-weight: 500;
}


/* .text-cream {
    color: #dbdbc5;
} */

.disconnect-btn {
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    background: unset;
    color: #fff;
    letter-spacing: 0.32px;
    font-size: 16px;
    text-transform: capitalize;
}

.horizontal-line {
    color: #1d469778;
    width: 90%;
    margin: auto;
}

.header-menu-container {
    max-width: 100%;
    padding: 7px 30px 5px 30px;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #383636;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #181c32e8;
    z-index: 99999;
}

.inner-loading-spinner {
    margin: auto;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #383636;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.swal2-popup {
    width: 25em !important;
    border-radius: 20px !important;
    background: #464d4f !important;
}

.swal2-styled.swal2-confirm {
    border: 1px solid !important;
    background: #981f2b !important;
    color: #dbdbc5 !important;
}

.swal2-html-container {
    color: #dbdbc5 !important;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px #f8f9fa !important;
}

.sub-para {
    color: #fff;
}

.font-w-600 {
    font-weight: 600;
}

.full-height {
    height: 100vh;
}

.stake-modal-body .table-body {
    max-height: 400px;
    /* height: 100px; */
    overflow-y: scroll;
}

.modal-90w {
    width: 70vw;
    max-width: 70vw;
}

@media (max-width: 991.98px) {
    .modal-90w {
        width: 100%;
        max-width: 100%;
    }
}

.droid-staked-per,
.droid-pool-balance {
    color: #dbdbc5;
    font-size: 20px;
    font-weight: 600;
}

.droid-stcked-container {
    background: #070b0d;
    padding: 13px;
    margin: 10px;
    border-radius: 20px;
}

.droid-staked-per-inside {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
}

.h-5px {
    height: 6px
}

.modal-content {
    border-radius: 20px;
    /* background: #1e4395; */
}

.stake-modal-body {
    background: #fff;
}

.modal-header {
    background: #981f2b !important;
    border-radius: 18px 20px 0px 0px;
    color: #fff;
}

.modal-footer {
    background: #fff;
}

.progress-bar {
    background-color: #981f2b !important;
}

.progress {
    background-color: #dbdbc5;
}

.unstake-all-btn,
.stake-all-btn,
.withdraw-all-btn {
    background: #981f2b !important;
    border-radius: 10px !important;
    color: #fff !important;
    white-space: nowrap !important;
    text-align: center !important;
    /* width: 100%; */
    /* box-shadow: 0 0 10px #212529; */
    /* text-transform: capitalize !important; */
    font-size: 10px !important;
    min-width: unset;
    padding: 0px 3px;
    line-height: 35px;
    width: 100%;
}

.stake-modal-btn {
    background: #981f2b !important;
    border-radius: 10px !important;
    color: #fff !important;
    white-space: nowrap !important;
    text-align: center !important;
    /* box-shadow: 0 0 10px #212529; */
    text-transform: capitalize !important;
    font-size: 16px;
}

.unstake-modal-btn {
    background: #981f2b !important;
    border-radius: 10px !important;
    color: #fff !important;
    white-space: nowrap !important;
    text-align: center !important;
    /* box-shadow: 0 0 10px #212529; */
    text-transform: capitalize !important;
    font-size: 16px;
}

.modal-btn-cancel {
    background: #070b0d !important;
    border-radius: 10px !important;
    color: #fff !important;
    white-space: nowrap !important;
    text-align: center !important;
    /* box-shadow: 0 0 10px #212529; */
    text-transform: capitalize !important;
    font-size: 16px;
}

.withdraw-btn,
.stake-btn,
.withdraw-and-unstake-btn {
    background: #91262c !important;
    border-radius: 10px !important;
    color: #fff !important;
    white-space: nowrap !important;
    text-align: center !important;
    /* width: 100%; */
    /* box-shadow: 0 0 10px #212529; */
    /* text-transform: capitalize !important; */
    font-size: 10px !important;
    min-width: unset;
    line-height: 35px;
    width: 100%;
    padding: 0 5px;
}

.stake-emission {
    background: #4472c4 !important;
    border-radius: 10px !important;
    color: #fff !important;
    white-space: nowrap !important;
    text-align: center !important;
    /* width: 100%; */
    /* box-shadow: 0 0 10px #212529; */
    /* text-transform: capitalize !important; */
    font-size: 10px !important;
    min-width: unset;
    line-height: 35px;
    width: 100%;
    padding: 0 5px;
}

.stake-time {
    /* top: -20px;
    position: relative; */
    background: #fffdd0;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(34px);
    width: 70%;
    margin: auto;
    text-align: center;
    padding: 5px;
    color: #070b0d;
    font-size: 12px !important;
    font-weight: 600;
}


/* .table>thead {
    background: #d78f09;
} */

tbody td {
    font-size: 16px;
}

.modal-sub-heading {
    color: #981f2b;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}

.modal-label span {
    color: #070b0d;
    font-size: 18px;
    font-weight: 600;
}

.swal2-title {
    color: #dbdbc5 !important;
}

.swal2-select {
    border-radius: 20px;
    color: #070b0d !important;
}

.popup-reward-earned {
    color: #070b0d;
    font-size: 20px;
    font-weight: 600;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
    background: rgb(0 0 0 / 89%);
}

.modal-backdrop {
    background: rgb(0 0 0 / 89%);
}

.modal-backdrop.show {
    opacity: 1;
}

.current-locking-period-text {
    color: #070b0d;
    font-size: 20px;
    font-weight: 600;
}

.upgrade-locking-period-text {
    color: #070b0d;
    font-size: 20px;
    font-weight: 600;
}

.nft-image-on-modal {
    width: 40px;
    border-radius: 8px;
    margin-right: 10px;
}

tbody tr:nth-child(odd) {
    background-color: #981f2b12;
}

.modal-title {
    font-size: 21px;
    font-weight: 600;
}

.footer {
    position: absolute;
    left: 0px;
    right: 0px;
    z-index: 3;
    bottom: 0px;
    width: 100%;
    /* background: transparent linear-gradient(92deg, #981f2b 0%, #981f2b 100%) 0% 0% no-repeat padding-box; */
}

.powered-by {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

.powered-by:hover {
    color: #fff;
}

.btn-close {
    background: url(./assest/close.png);
    background-repeat: no-repeat;
    background-size: 70% 70%;
    opacity: unset;
}

.loading-text {
    font-size: 19px;
    position: relative;
    top: 250px;
    text-align: center;
    color: #fff !important;
}

.admin-btn {
    position: absolute;
    top: 20px;
    right: 10px;
    background: unset;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    padding: 0px 35px;
}

.tab-content {
    max-height: 45vh;
    min-height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;
}


/* table */

.table-2 {
    --accent-color: #362f4b;
    --text-color: slategray;
    --bgColorDarker: #ececec;
    --bgColorLighter: #fcfcfc;
    --insideBorderColor: lightgray;
    width: 100%;
    margin: 0;
    padding: 0;
    border: unset;
    border-collapse: collapse;
    color: var(--text-color);
    table-layout: fixed;
}

.caption {
    margin: 0.4rem 0;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.055rem;
    border-bottom: 1px solid #ffffff;
}

.table-2 thead tr {
    color: whitesmoke;
    /* background-color: var(--accent-color); */
    font-size: 1rem;
}

.table-2 tbody td {
    font-size: 13px;
    /* border: 1px solid var(--insideBorderColor);
    background-color: var(--bgColorDarker); */
}

.table-2 tbody tr:nth-child(odd) {
    background-color: unset;
}

.table-2 th {
    letter-spacing: 0.075rem;
}

.table-2 th,
.table-2 td {
    padding: 0.25rem 1rem;
    font-weight: normal;
    text-align: left;
    color: #dbdbc5;
}

.table-2 th:nth-child(4),
.table-2 td:nth-child(4) {
    text-align: right;
}

@media screen and (max-width: 768px) {
    .table-2 {
        border: none;
    }
    .table-2 caption {
        padding: 0.75rem 1rem;
        border-radius: 6px 6px 0 0;
        color: whitesmoke;
        font-size: 1.35rem;
        background-color: var(--accent-color);
    }
    .table-2 thead {
        position: absolute;
        width: 1px;
        height: 1px;
        clip: rect(0 0 0 0);
        overflow: hidden;
    }
    .table-2 tbody tr {
        margin-bottom: 2rem;
        display: block;
    }
    .table-2 td {
        font-size: 15px !important;
    }
    .unstake-form label,
    .unstake-form div {
        display: block !important;
    }
    .column {
        width: 100%;
        margin-bottom: 10px;
    }
}

.td-w-70 {
    width: 70%;
}

.td-w-30 {
    width: 30%;
}

#week-1 {
    background-color: #1d0608;
    border: 1px solid;
}

#week-2 {
    background-color: #3a0c11;
    border: 1px solid;
}

#week-3 {
    background-color: #571319;
    border: 1px solid;
}

#week-4 {
    background-color: #741d22;
    border: 1px solid;
}

#week-5 {
    background-color: #91262b;
    border: 1px solid;
}

.rating {
    /* position: relative;
    right: 20px; */
    float: right;
    position: relative;
    top: 30px;
    /* right: -10px; */
    unicode-bidi: bidi-override;
}

.rating>div>span:first-child {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 15px;
}

.week-text {
    color: #dbdbc5;
    font-size: 15px;
}

.tabs .nav-tabs {
    border-bottom: 1px solid #212529;
}

.tabs .nav-tabs .nav-item.show .nav-link,
.tabs .nav-tabs .nav-link.active {
    border-color: #212529 #212529 #212529;
}

.tabs .nav-tabs .nav-item.show .nav-link,
.tabs .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #070b0d;
}

.tabs .nav-tabs .nav-link:focus,
.tabs .nav-tabs .nav-link:hover {
    border-color: #212529 #212529 #212529;
}

.tabs .nav-link {
    color: #808080;
    cursor: pointer;
}

.tab-content::-webkit-scrollbar {
    width: 12px;
}

.tab-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #91262b;
    border-radius: 10px;
}

.tab-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #91262b;
}

.right-side-tab .tab-content {
    border: 1px solid;
    border-radius: 35px;
    padding: 10px;
    background: #070b0d;
    display: grid;
    align-items: center;
    justify-content: center;
    /* min-width: 400px; */
}

.right-side-tab .nav-tabs {
    border-bottom: unset;
}

.stake-note {
    font-size: 12px;
    color: #dbdbc5;
    margin-top: 10px;
}

.unstake-form {
    padding: 1em;
    background: rgba(0, 0, 0, 0.1);
    display: table;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.unstake-form label,
.unstake-form div {
    /* display: table-cell; */
    font-weight: bold;
    margin-bottom: 1em;
    height: 1em;
    vertical-align: middle;
}

.unstake-form div {
    width: 100%;
}

.unstake-form label {
    white-space: nowrap;
    padding-right: 1em;
    color: #fff;
}

.unstake-form input {
    background: #070b0d;
    border: 1px solid #212529;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    color: #fff;
    width: 100%;
}

.admin-statistics {
    border: 1px solid;
    border-radius: 20px;
    margin-top: 7px;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #070b0d;
}

.rounded-social-buttons {
    position: absolute;
    left: 0px;
    bottom: 50px;
}

.rounded-social-buttons .social-button {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 3.125rem;
    height: 3.125rem;
    border: 0.125rem solid transparent;
    padding: 0;
    text-decoration: none;
    text-align: center;
    color: #fefefe;
    font-size: 1.5625rem;
    font-weight: normal;
    line-height: 2em;
    transition: all 0.5s ease;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    background: #070b0d;
}

.rounded-social-buttons .social-button img {
    width: 23px;
}

.rounded-social-buttons .social-button:hover,
.rounded-social-buttons .social-button:focus {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}

.slider-img {
    width: 100px;
}

.slider-container {
    position: absolute;
}

.caption-2 {
    font-size: 14px;
    color: #fff;
    text-align: center;
}

.mx-a {
    margin: auto;
}

.moon-rank {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #91262c;
    /* margin: auto; */
    border-radius: 20px;
    font-size: 12px;
    padding: 2px 5px 2px 5px;
    font-weight: 600;
}

.moon-rank span {
    padding-right: 5px;
    font-size: 16px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .col-left-cont {
        width: 100%;
    }
    .col-right-cont {
        width: 100%;
    }
    html,
    body {
        height: 130vh;
        overflow: scroll;
    }
    .wallet-adapter-modal-container {
        min-height: unset;
    }
    .column {
        width: 100%;
        margin-bottom: 10px;
    }
}